import React, { useEffect, useState } from "react";
import { Base64 } from "js-base64";
import { Helmet } from "react-helmet";
import { Spinner } from "./components/spinner/spinner";
import { AppRouter } from "./routers/appRouter";
import { AuthRouter } from "./routers/authRouter";
import { PublicRoute } from "./routers/publicRoute";
import { PrivateRoute } from "./routers/privateRoute";
import { saveLastUsed } from "./helpers/unloadEvent/unloadEvent";
import { refreshUserSession } from "./actions/auth";
import { ConfirmLoginScreen } from "./views/confirmLoginScreen/confirmLoginScreen";
import { useSelector, useDispatch } from "react-redux";
import { Footer } from "./components/footer/footer";
import {
	BrowserRouter as Router,
	Switch,
	Redirect,
	Route,
} from "react-router-dom";
import "./App.css";

export const App = () => {
	const dispatch = useDispatch();
	const { loadingUserSession, successUserSession } = useSelector(
		(state) => state.auth,
	);

	const [confirmForm, setConfirmForm] = useState(false);

	const setAvailableArea = () => {
		let vh = (window.innerHeight * 0.01) - 0.02; //agrego -0.02 mp 01/03/2022
		document.documentElement.style.setProperty("--vh", `${vh}px`);
	};

	useEffect(() => {
		setAvailableArea();
		window.addEventListener("resize", setAvailableArea);
		return () => window.removeEventListener("resize", setAvailableArea);
	});

	useEffect(() => {
		dispatch(refreshUserSession());
	}, [dispatch]);

	useEffect(() => {
		return () => {
			window.addEventListener("unload", (e) => {
				saveLastUsed();
			});
		};
	}, []);

	const titleLogin = "aLCHEMY Login";
	const titleApp = "aLCHEMY Menu";

	const location = window.location;
	if (location && !confirmForm) {
		try {
			const queryString = window.location.search;
			const urlParams = new URLSearchParams(queryString);
			const sessionParam = urlParams.get("session");
			if (sessionParam) {
				const decoded1 = Base64.decode(sessionParam);
				const decoded2 = JSON.parse(decoded1);
				const url = decoded2.url_fe_menu;
				if (location.href.includes(url)) {
					setConfirmForm(true);
				}
			}
		} catch (error) {
			//const urlDomain = getDomain("logoutRedirect");
			//window.location.href = urlDomain;
			console.log("ERROR - App: ", error); //comento las dos líneas anteriores y agrego console.log mp 30/11/2021
		}
	}

	if (loadingUserSession) {
		return (
			<div className="app--main--container">
				<Spinner />
			</div>
		);
	}

	return (
		<Router>
			<div className="app--main--container">
				<Helmet>
					<meta charSet="utf-8" />
					<title>{successUserSession ? titleApp : titleLogin}</title>
				</Helmet>
				<Switch>
					{confirmForm == true && (
						<Route path="/aLCHEMY" component={ConfirmLoginScreen} />
					)}

					<PublicRoute
						isAuthenticated={successUserSession}
						path="/auth"
						component={AuthRouter}
					/>

					<PrivateRoute
						isAuthenticated={successUserSession}
						path="/"
						component={AppRouter}
					/>
					<Redirect to="/auth/login" />
				</Switch>
			</div>
			<Footer />
		</Router>
	);
};
