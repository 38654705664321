import React, { useEffect, useState } from "react";
import jwt_decode from "jwt-decode";
import { Home } from "../views/home/home";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Header } from "../components/header/header";
import { Footer } from "../components/footer/footer";
import { Sidebar } from "primereact/sidebar";
import { ProfileForm } from "../components/profileForm/profileForm";
import { Notification } from "../components/notification/notification";
import { SidebarContent } from "../components/sidebarContent/sidebarContent";
import { Switch, Redirect, Route } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import {
	getLastUsedProcesses,
	getMenuData,
	getProcessesFavorites,
	openNotificationModal,
	openProfileModal,
	toggleSidebar,
} from "../actions/ui";
import "./routerStyles.css";
import { useTranslation } from "react-i18next"; //agregado para idiomas 10/11/2021

export const AppRouter = () => {
	const { t, i18n } = useTranslation("global"); //agregado 10/11/2021
	const dispatch = useDispatch();
	const state = useSelector((state) => state.ui);
	const {
		openSidebar,
		openModalProfile,
		openModalNotification,
		selectedNotification,
	} = state;

	const [activeMenu, setActiveMenu] = useState(0);

	const tooltipOptions = {
		position: "bottom",
		mouseTrack: true,
		mouseTrackTop: 15,
		style: {zIndex: 9900}
	};

	const customIcons = (
		<div className="sidebar--header--icon--container">
			<div className="sidebar--header--icon--container--title active--favorites">
				{activeMenu == 0
					? `${t("options.menu")}`
					: activeMenu == 1
					? `${t("options.lastused")}`
					: `${t("options.fav")}`}
			</div>
			<div className="sidebar--header--icon--container--icons">
				<Button
					type="button"
					tooltip={t("options.menu")}//"Menu Principal"
					tooltipOptions={tooltipOptions}
					onClick={() => setActiveMenu(0)}
					className={`p-sidebar-icon`}
					aria-haspopup
				>
					<i
						className={`${
							activeMenu === 0 ? "fas fa-bars active--menu" : "fas fa-bars"
						}`}
						alt="Menu"
					/>
				</Button>

				{/* **************************************************************** */}
				<Button
					type="button"
					tooltip={t("options.lastused")}//"Ultimos Usados"
					tooltipOptions={tooltipOptions}
					onClick={() => {
						setActiveMenu(1);
						dispatch(getLastUsedProcesses());
					}}
					className={`p-sidebar-icon`}
					aria-haspopup
				>
					<i
						className={`${
							activeMenu === 1
								? "fas fa-history active--last--used"
								: "fas fa-history"
						} `}
						alt="Last used"
					/>
				</Button>

				{/* **************************************************************** */}

				<Button
					type="button"
					tooltip={t("options.fav")}//"Favoritos"
					tooltipOptions={tooltipOptions}
					onClick={() => setActiveMenu(2)}
					className={`p-sidebar-icon`}
					aria-haspopup
				>
					<i
						className={`${
							activeMenu === 2 ? "fas fa-star active--favorites" : "far fa-star"
						} `}
						alt="Favorites"
					/>
				</Button>
			</div>
		</div>
	);

	let sessionData = JSON.parse(localStorage.getItem("user-session")) || null;

	useEffect(() => {
		if (!sessionData) {
			window.location.href = "/auth/login";
		}
	}, [sessionData]);

	useEffect(() => {
		async function asyncFunction() {
			dispatch(getMenuData());
			if (localStorage.getItem("logged2") === "true") {
				const token = localStorage.getItem("token");
				const { user, conn } = jwt_decode(token); 
				dispatch(getProcessesFavorites(user, conn));
				dispatch(getLastUsedProcesses(user, conn));
			} else {
				confirmDialog({
					message: (
						<div>
							{t("options.tokenexpired")}
						</div>
					),
					header: "",
					icon: "pi pi-exclamation-triangle",
					acceptLabel: t("options.labelaccept"),
					rejectLabel: t("options.labelcancel"),
					accept: () => {
						const urlDomain = localStorage.getItem("reflogout");
						localStorage.clear();
						sessionStorage.clear(); 
						window.location.href = urlDomain;
					},
					reject: () => {return false;}
				});
			}
		  }
		asyncFunction();
	}, []);

	return (
		<div className="router--main--container">
			<Header />
			<div
				className={`layout--sidebar--main--container ${
					openSidebar ? "opened--sidebar" : ""
				}`}
			>
				<Sidebar
					visible={openSidebar}
					className="sidebar--main--container"
					onHide={() => dispatch(toggleSidebar())}
					icons={customIcons}
				>
					<SidebarContent activeMenu={activeMenu} />
				</Sidebar>
			</div>
			<Dialog
				visible={openModalProfile}
				draggable={false}
				onHide={() => dispatch(openProfileModal())}
			>
				<ProfileForm />
			</Dialog>
			<Dialog
				visible={openModalNotification}
				draggable={false}
				breakpoints={{ "960px": "75vw", "640px": "100vw" }}
				style={{ width: "50vw" }}
				onHide={() => dispatch(openNotificationModal(null))}
			>
				{selectedNotification && (
					<Notification notification={selectedNotification} />
				)}
			</Dialog>
			<Switch>
				<Route exact path="/" component={Home} />
				<Redirect to="/" />
			</Switch>
			
			<ConfirmDialog /> 
		</div>
	);
};
