import React, { useEffect, useState } from "react";
import { InputOtp } from "primereact/inputotp";
import { ConfirmDialog } from "primereact/confirmdialog";
import { Button } from "primereact/button";
import "primeflex/primeflex.css";

export const ModalPin = ({
	visible,
	setVisible,
	pin,
	setPin,
	onSubmit,
	headerTitle,
	bodyMessage,
	footerMessage,
}) => {
	const lenghtPin = 6;
	const [disabled, setDisabled] = useState(false);

	useEffect(() => {
		if (pin.length === lenghtPin) {
			setDisabled(!disabled);
			onSubmit();
		}
	}, [pin]);

	const accept = () => {
		setVisible(!visible);
		onSubmit();
	};

	const reject = () => {
		setVisible(!visible);
		window.location.href = "/";
	};

	return (
		<ConfirmDialog
			group="headless"
			visible={visible}
			accept
			reject
			content={({ hide }) => (
				<div className="flex flex-column align-items-center p-5 surface-overlay border-round">
					<div className="border-circle bg-primary inline-flex justify-content-center align-items-center h-6rem w-6rem -mt-8">
						<i className="pi pi-shield text-5xl"></i>
					</div>
					<span className="font-bold text-2xl block mb-2 mt-4">
						{headerTitle}
					</span>
					<p className="mb-0">{bodyMessage}</p>

					<div className="flex align-items-center gap-2 mt-4">
						<InputOtp
							autoFocus
							value={pin}
							length={lenghtPin}
							onChange={(e) => setPin(e.value)}
							integerOnly
							disabled={disabled}
						/>
					</div>
					<div className="flex align-items-center gap-2 mt-4">
						<Button
							label="Confirm"
							onClick={(event) => {
								hide(event);
								accept();
							}}
							className="w-8rem"
							disabled={disabled}
						></Button>
						<Button
							label="Cancel"
							outlined
							onClick={(event) => {
								hide(event);
								reject();
							}}
							className="w-8rem"
							disabled={disabled}
						></Button>
					</div>
					<p className="mb-0 text-xs text-red-400">{footerMessage}</p>
				</div>
			)}
		/>
	);
};
