import React, { useEffect, useState } from "react";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";

export const FormInputText = ({
	field,
	fieldState,
	classNameIcon,
	placeholder = "",
	errorMessage = "",
	disabled = false,
}) => {
	const labelLeftValueFloat = 8;
	const labelLeftValuePadding = 38;

	const [labelLeft, setLabelLeft] = useState(labelLeftValuePadding);

	const calculateLeftLabel = (left) => {
		if (left > 0 && field.value.length >= 1) {
		} else {
			setLabelLeft(left);
		}
	};

	useEffect(() => {
		if (field.value.length > 0 && labelLeft === labelLeftValuePadding) {
			setLabelLeft(labelLeftValueFloat);
		}
	}, [field.value]);

	return (
		<div style={{ margin: "28px 10px" }}>
			<div className="p-float-label">
				<InputText
					disabled={disabled}
					id={field.name}
					{...field}
					onFocus={() => calculateLeftLabel(labelLeftValueFloat)}
					onBlur={() => calculateLeftLabel(labelLeftValuePadding)}
					style={{
						paddingLeft: 35,
						backgroundColor: disabled ? "#e1e5e8" : undefined,
					}}
					className={classNames({
						"p-invalid": fieldState.invalid,
					})}
				/>
				<label htmlFor={field.name} style={{ left: labelLeft }}>
					{placeholder}
				</label>
				<i
					className={classNameIcon}
					style={{
						position: "absolute",
						left: 10,
						top: 12,
						color: disabled ? "#919599" : undefined,
					}}
				></i>
			</div>
			{fieldState.invalid && (
				<small className="p-error">{errorMessage}</small>
			)}
		</div>
	);
};
