import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import useDynTabs from "react-dyn-tabs";
import "react-dyn-tabs/style/react-dyn-tabs.css";
import "react-dyn-tabs/themes/react-dyn-tabs-bootstrap.min.css";
import "./home.css";
import { useTranslation } from "react-i18next";
import { confirmDialog } from "primereact/confirmdialog";
//import { ProgressSpinner } from "primereact/progressspinner";

export const Home = () => {
	const { t, i18n } = useTranslation("global");
	const state = useSelector((state) => state.ui);
	const {
		loadingNotifications,
		newSelectedNodeTab,
		errorNotifications,
		notifications,
	} = state;

	const [counter, setCounter] = useState(0);
	const [closeConfirmed, setCloseConfirmed] = useState(false);

	let _instance;

	/*useEffect(() => {
		const handleBeforeUnload = (event) => {
			event.preventDefault();
			event.returnValue ='';
		};
		window.addEventListener('beforeunload', handleBeforeUnload);
		return () => {
		  	window.removeEventListener('beforeunload', handleBeforeUnload);
		};
	}, []);

	useEffect(() => {
		const handleUnload = () => {
			localStorage.clear();
			sessionStorage.clear(); 
		};
		window.addEventListener('unload', handleUnload);
		return () => {
		  window.removeEventListener('unload', handleUnload);
		};
	}, []);*/


	const [TabList, PanelList, ready] = useDynTabs({
		/*beforeClose: (e, id) => {
			if (target === 'X'){
				return (window.confirm(t("options.confirmCloseTab")));
			}
		},*/
		onClose: (closedTabIDs) => {
			setCounter((c) => c - 1);
		},
	});

	const showConfirmDialog = () => {
		confirmDialog({
			message: (
				<div>
					{t("options.confirmCloseTab")}
				</div>
			),
			header: "",
			icon: "pi pi-exclamation-triangle",
			accept: () => {
				setCloseConfirmed(true);
			  },
			reject: () => {
				setCloseConfirmed(false);
			}
		});
	}

	ready((instance) => {
		_instance = instance;
	});

	const openNewTab = (newSelectedNodeTab) => {
		ready(function (_instance) {
			/*console.log(newSelectedNodeTab.procType+' '+newSelectedNodeTab.execType);
			_instance.open(newSelectedNodeTab).then(({instance}) => {
				if (newSelectedNodeTab.procType ==='A' && newSelectedNodeTab.execType === 'F'){
					//_instance.setOption('beforeClose', () => {return (window.confirm(t("options.confirmCloseTab")));});
				}
				else {
					//_instance.setOption('beforeClose', () => {return true});
				}
			});*/
			_instance.open(newSelectedNodeTab).then((x) => {});
			_instance.select(newSelectedNodeTab.id).then((x) => {});
			setCounter((c) => c + 1);
		});
	};

	const changeClassName = (element, removeClassName, newClassName) => {
		element.classList.remove(removeClassName);
		element.classList.add(newClassName);
	};

	useEffect(() => {
		if (counter == 0) {
			let elem = document.getElementById("home-main-container-tabs-content");
			changeClassName(
				elem,
				"container-tabs-content-show",
				"container-tabs-content-hide",
			);
		}
	}, [counter]);

	useEffect(() => {
		let elem = document.getElementById("home-main-container-tabs-content");
		if (elem != undefined) {
			if (newSelectedNodeTab) {
				changeClassName(
					elem,
					"container-tabs-content-hide",
					"container-tabs-content-show",
				);
				openNewTab(newSelectedNodeTab);
			} else {
				try {
					changeClassName(
						elem,
						"container-tabs-content-show",
						"container-tabs-content-hide",
					);
				} catch (error) {}
			}
		}
	}, [newSelectedNodeTab]);

	useEffect(() => {
		let elem = document.getElementById("home-main-container-tabs-content");
		if (elem != undefined) {
			if (notifications) {
				changeClassName(
					elem,
					"container-tabs-content-hide",
					"container-tabs-content-show",
				);
				openNewTab(notifications); //aca tiene que cambiar el idNotifications para refrescar la tabla
			} else {
				try {
					changeClassName(
						elem,
						"container-tabs-content-show",
						"container-tabs-content-hide",
					);
				} catch (error) {}
			}
		}
	}, [notifications]);

	/*if (loadingNotifications){
		return (
			<div className="home--main--container">
				<ProgressSpinner />
			</div>
		);
	}*/

	if (errorNotifications && !loadingNotifications) {
		return <div>Error loading notifications...</div>;
	} else {
		return (
			<div className="home--main--container">
				<div className="container--tab--list">
					<div className="home--main--container--tabs--ext" />
					<div
						id="home-main-container-tabs-content"
						className="home--main--container--tabs--content"
					>
						<TabList></TabList>
						<PanelList></PanelList>
					</div>
					<div className="home--main--container--tabs--ext" />
				</div>
			</div>
		);
	}
};
