import React, { useEffect, useState, useRef } from "react";
import { Toast } from "primereact/toast";
import jwt_decode from "jwt-decode";
import { Tree } from "primereact/tree";
import { Spinner } from "../spinner/spinner";
import { setHeadersToken } from "../../api/api";
import {
	updateFavoritesMenuData,
	generateTree,
	isProcessNode,
	updateFavoriteMenuList,
} from "../../helpers/sidebar";
import { createNodeTab } from "../../helpers/sidebar";
import { useSelector, useDispatch } from "react-redux";
import {
	selectNewNodeForTab,
	updateFavoriteList,
	updateMenuBySelectFavorite,
	toggleSidebar,
} from "../../actions/ui";
import { FavProcess } from "../favProcess/favProcess";
import { LastUsedProcess } from "../lastUsedProcess/lastUsedProcess";
import { getUrl, getDomain } from "../../actions/getUrl";
import "./sidebarContent.css";

import { useTranslation } from "react-i18next";
import { confirmDialog } from "primereact/confirmdialog";

export const SidebarContent = ({ activeMenu }) => {
	const { t, i18n } = useTranslation("global");
	const toastBL = useRef(null);
	const dispatch = useDispatch();
	const {
		loadingMenuData,
		menuData,
		lastUsed,
		favorites,
	} = useSelector((state) => state.ui);

	const [nodes, setNodes] = useState(null);
	const [expandedKeys, setExpandedKeys] = useState({});

	useEffect(() => {
		if (nodes && activeMenu === 0) {
			const searchInputText = document.querySelector(".p-inputtext");
			if (searchInputText) {
				searchInputText.focus();
			}
		}
	}, [nodes, activeMenu]);

	const saveOrDeleteFavorites = async (node) => {
		const { nodo, proceso, guardadofavorito } = node;
		const token = localStorage.getItem("token");
		const { user, conn } = jwt_decode(token); 
		const urlDomain = JSON.parse(localStorage.getItem("user-data-url"));
		const domain = urlDomain.url_be_menu; //cambio mp 29/11/2021
		//const domain = await getDomain("domain");
		const params = `?userid=${user}&connection=${conn}&proceso=${proceso}`;
		let uri = "";
		if (guardadofavorito == "Si") {
			uri = await getUrl(domain, "deleteFavorites", params); // Si esta en favoritos, entonces lo quita.
		} else {
			uri = await getUrl(domain, "saveFavorites", params); // Si NO esta en favoritos, entonces lo agrega.
		}
		const requestOptions = setHeadersToken("POST");
		try {
			const res = await fetch(uri, requestOptions);
			if (res.status == 200) {
				const response = await res.json();
				if (response.error == "0") {
					const updatedFavorites = updateFavoriteMenuList(
						favorites,
						node,
						guardadofavorito,
					);
					dispatch(updateFavoriteList(updatedFavorites));
					const updateMenuData = updateFavoritesMenuData(menuData, node);
					dispatch(updateMenuBySelectFavorite(updateMenuData));
					//actualizar ícono
					//tagnode = document.getElementById(node.nodo);
					//tagnode.setAttribute("class", node.guardadofavorito == "Si" ? "star--icon--main--container fas fa-star" : "star--icon--main--container far fa-star");
				} else {
					showBottomLeft("error", response.message);
				}
			} else {
				showBottomLeft("error", "Error en servicio. No se pudo completar la operación");
			}
		} catch (error) {
			showBottomLeft("error", "Error. No se pudo completar la operación");
		}
	};

	const showBottomLeft = (sev="", det="") => {
		toastBL.current.show({
			severity: sev,
			summary: "Proceso Favoritos",
			detail: det,
			life: 2500,
		});
	};

	const NodeTemplate = (node, options) => {
		if (isProcessNode(node)) {
			return (
				<span className={options.className} style={iconsStyles}>
					<div>{node.label}</div>
					<i
						id={node.nodo}
						className={`star--icon--main--container ${
							node.guardadofavorito == "Si" ? "fas fa-star" : "far fa-star"
						}`}
					/>
				</span>
			);
		} else {
			return <span className={options.className}>{node.label}</span>;
		}
	};

	const onSelect = async (e) => {
		const tagElement = e.originalEvent.target.tagName;
		if (tagElement === "I") {
			saveOrDeleteFavorites(e.node);
		} else {
			if (isProcessNode(e.node)) {
				selectProcess(e.node);
				addLastUsed(e.node);
			} else {//agregado 22/12/2021 //si no es un proceso es un menú y lo expande
				let _expandedKeys = {...expandedKeys};
				if (expandedKeys[e.node.key]) {
					delete _expandedKeys[e.node.key];
				} else {
					_expandedKeys[e.node.key] = true;
				}
				setExpandedKeys(_expandedKeys);
			}

		}
	};

	const addLastUsed = (node) => {
		const newNode = {
			nodo: node.nodo,
			ruta: node.ruta,
			fuente_icono: node.fuente_icono,
			comando: node.comando,
			guardadofavorito: node.guardadofavorito,
			nombre: node.nombre,
			proceso: node.proceso,
			tipo_proceso: node.tipo_proceso,
			where_base: node.where_base,
		};
		let lastUsedList = JSON.parse(sessionStorage.getItem("alchemy_last_used"));
		if (lastUsedList != null) {
			if (!lastUsedList.find((x) => x.nodo == newNode.nodo)) {
				lastUsedList = [...lastUsedList, newNode];
				if (lastUsedList.length == process.env.REACT_APP_MAX_LAST_USED) {
					lastUsedList.shift();
				}
				let NewElems = JSON.stringify(lastUsedList);
				sessionStorage.setItem("alchemy_last_used", NewElems);
			}
		} else {
			let firstElem = JSON.stringify([newNode]);
			sessionStorage.setItem("alchemy_last_used", firstElem);
		}
	};

	const openInNewTab = (url) => {
		const newWindow = window.open(url, "_blank", "noopener,noreferrer");
		if (newWindow) newWindow.opener = null;
	};

	const typeProccesU = (data, nodo, proceso, comando) => {
		const newNodeU = createNodeTab(
			data,
			<iframe
				id={"frame-" + nodo}
				title={proceso}
				frameBorder="0"
				width="100%"
				height="100%"
				onLoad={() => {}}
				src={comando}
			></iframe>,
			data.tipo_proceso
		);
		dispatch(selectNewNodeForTab(newNodeU));
	};

	//cambio mp 30/09/2021
	const typeProccesA = async (data) => {
		try {
			const { url_be_form, url_fe_form } = JSON.parse(
				localStorage.getItem("user-data-url"),
			);
			const { company, country } = JSON.parse(
				localStorage.getItem("user-session"),
			);
			const token = localStorage.getItem("token");
			const lang = localStorage.getItem("language");
			const { user, conn } = jwt_decode(token); //cambio id/db por user/conn
			const operacion = data.tipo_proceso==="R" ? 'Nuevo':'';
			//const urlFront = url_fe_form;// + data.proceso; cambio 01/03/2022
			const body = {
				PROCESO: data.proceso, //agrego 01/03/2022
				URL_FRONT: url_fe_form, //urlFront.trim(),  cambio 01/03/2022
				URL_BE: url_be_form,
				TIPO_EJECUCION: data.tipo_ejecucion,// data.tipo_proceso, cambio 01/03/2022
				OPERACION: operacion,
				EMPRESA: company, //va en el token
				PAIS: country, //va en el token
				USUARIO: user, //va en el token
				conn: conn, //va en el token
				IDIOMA: lang,
				FE_AUTO: "N",
				CLAVE: {
				},
			};
			//console.log("BODY: ", body);
			//const domain = await getDomain("domain");
			const urlDomain = JSON.parse(localStorage.getItem("user-data-url"));
			const domain = urlDomain.url_be_menu; //cambiado mp 29/11/2021
			const uri = await getUrl(domain, "postForms", "");
			const res = await fetch(uri, {
				method: "POST",
				headers: {
					Authorization: `bearer ${token}`,
					"Content-Type": "application/json",
					Accept: "application/json",
				},
				body: JSON.stringify(body),
			});
			//console.log(res);
			if (res.status == 201) {
				const response = await res.json();
				//console.log("SUCCESS: ", response);
				const newNodeA = createNodeTab(
				data,
				<iframe
					id={"frame-" + data.nodo}
					title={data.proceso}
					frameBorder="0"
					width="100%"
					height="100%"
					onLoad={() => {}}
					src={response.url_form}
				></iframe>,
				data.tipo_proceso,
				data.tipo_ejecucion,
				);
				dispatch(selectNewNodeForTab(newNodeA));
			} else {
				console.log("ERROR: ", "BAD STATUS");
			}
		} catch (error) {
			console.log("ERROR: ", error);
		}
	};	

	const selectProcess = (data) => {
		const { tipo_proceso, comando, nodo, proceso } = data;
		let url = ""
		const myToken = localStorage.getItem("token");
		if (String(comando).includes("?")){
			url = comando + "token=" + myToken; //authdata;
		} else {url = comando}
		if (comando.trim() == 'alchemy') {//proceso aLCHEMY
			typeProccesA(data);
			dispatch(toggleSidebar()); //ocultar el menú mp 04/10/2021
		} else {
			switch (tipo_proceso) {
				case "U": //url en un tab interno
					typeProccesU(data, nodo, proceso, url);
					dispatch(toggleSidebar());
					break;
				case "H": //hyperlink en nueva pestaña
					openInNewTab(url);
					dispatch(toggleSidebar());
					break;
				default:
					// abre una nueva ventana
					openInNewTab(comando); 
					dispatch(toggleSidebar());
					break;
			}
		}
	};

	const onClickFavProcess = async (process) => selectProcess(process);
	const onClickLastUsedProcess = async (process) => selectProcess(process);

	const removeFavProcess = async (node) => {
		const token = localStorage.getItem("token");
		const { user, conn } = jwt_decode(token);
		const urlDomain = JSON.parse(localStorage.getItem("user-data-url"));
		const domain = urlDomain.url_be_menu;
		const params = `?userid=${user}&connection=${conn}&proceso=${node.proceso}`;
		const uri = await getUrl(domain, "deleteFavorites", params);
		const requestOptions = setHeadersToken("POST");
		try {
			const res = await fetch(uri, requestOptions);
			if (res.status == 200) {
				const response = await res.json();
				if (response.error == "0") {
					const updatedFavorites = updateFavoriteMenuList(
						favorites,
						node,
						"Si",
					);
					dispatch(updateFavoriteList(updatedFavorites));
					const updateMenuData = updateFavoritesMenuData(menuData, node);
					dispatch(updateMenuBySelectFavorite(updateMenuData));
				} else {
					showBottomLeft("error", response.message);
				}
			} else {
				showBottomLeft("error", "Error en servicio. No se pudo completar la operación");
			}
		} catch (error) {
			showBottomLeft("error", "Error. No se pudo completar la operación");
		}
	};

	useEffect(() => {
		if (menuData) {
			if (menuData.error === "401" || menuData.error === "401.1") { //mostrar mensaje si hay error? 401.1
				dispatch(toggleSidebar()); //ocultar el menú
				localStorage.setItem("logged2", false); //seteo que no estara logueado
				//console.log(menuData.message);
				//lo paso a appRouter.js //lo dejo aca tambien
				confirmDialog({
					message: (
						<div>
							{t("options.tokenexpired")}
						</div>
					),
					header: "",
					icon: "pi pi-exclamation-triangle",
					acceptLabel: t("options.labelaccept"),
					rejectLabel: t("options.labelcancel"),
					accept: () => {
						const urlDomain = localStorage.getItem("reflogout");
						localStorage.clear();
						sessionStorage.clear(); 
						window.location.href = urlDomain;
					},
					reject: () => {return false;}
				});
			} else {
				const data = generateTree(menuData);
				setNodes(data);
			}
		}
	}, [menuData]);

	if (loadingMenuData) {
		return (
			<div className="sidebar--component--main--container">
				<Spinner />
			</div>
		);
	}

	return (
		<div className="sidebar--component--main--container">
			<div className="p-field p-col-12 p-md-4"></div>
			{favorites && activeMenu == 2 ? (
				<div className="sidebar--component--main--container--favorites">
					{favorites.map((process, index) => (
						<FavProcess
							key={index}
							process={process}
							onClickFavProcess={() => onClickFavProcess(process)}
							removeProcess={() => removeFavProcess(process)}
						/>
					))}
				</div>
			) : nodes && activeMenu == 0 ? (
				<Tree
					value={nodes}
					expandedKeys={expandedKeys}
					onToggle={e => setExpandedKeys(e.value)}
					selectionMode="single"
					nodeTemplate={NodeTemplate}
					filter
					filterMode="strict"
					onSelect={onSelect}
				/>
			) : lastUsed && activeMenu == 1 ? (
				<div className="sidebar--component--main--container--favorites">
					{lastUsed.map((element, index) => (
						<LastUsedProcess
							key={index}
							element={element}
							onClickLastUsedProcess={() => onClickLastUsedProcess(element)}
						/>
					))}
				</div>
			) : (
				<div>Loading...</div>
			)}
			<Toast ref={toastBL} position="bottom-left" />
		</div>
	);
};

const iconsStyles = {
	positon: "relative",
	display: "flex",
	flexDirection: "row",
	alignItems: "center",
	justifyContent: "space-between",
	width: "100%",
	paddingRight: "10px",
};
