/* eslint-disable no-duplicate-case */
import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import "./notificationTable.css";
import { ProgressSpinner } from "primereact/progressspinner";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import jwt_decode from "jwt-decode";
import { getUrl } from "../../actions/getUrl";

export const NotificationTable = ({ jsonData }) => {
	const [notificationsData, setNotificationsData] = useState([]);
	const [expandedRows, setExpandedRows] = useState(null);
	const [loading, setLoading] = useState(true);
	
	// Hook para manejar el modal del form 
	const [contentModal, setContentModal] = useState(null);
	const [showMenuModal, setShowMenuModal] = useState(false);
	const [headerTitle, setheaderTitle] = useState('');

	// Referencia para el componente Toast (notificaciones)
	const toast = useRef(null);
	

	// Referencia para desabilitar botones de acciones
	const [disabledButtons, setDisabledButtons] = useState({});

	useEffect(() => {
       // setNotificationsData(jsonData);
		setNotificationsData([...jsonData]); // Forzar nueva referencia
        setLoading(false); 
    }, [jsonData]);

	const formatFloat = (value) => {
		return new Intl.NumberFormat("es-ES", {
			minimumFractionDigits: 2,
			maximumFractionDigits: 2,
		  }).format(value);
	};

	const formatDate = (value) => {
		if (!value) return ""; 
  		const date = new Date(value.replace(" ", "T"));
  		return isNaN(date) ? "Fecha inválida" : date.toLocaleDateString("es-ES"); 
	};

	const executeOption = (option, data) => {
		// Según el tipo de operación, se ejecutan diferentes acciones
		//console.log(option);
		switch (option) {
			case "envia_mail":
			case "autorizar":
			case "rechazar":
				callNotifAction(data, option);
				break;
			case "imprimir":
				callNotifPrint(data, option);
				break;
			case "visualizar":
			case "nuevo":
				data.operacion=option;
				callProccesA(data);
				break;
			default:
				break;
		}
	}

	const callNotifAction = (rowdata, action) => {
		try {
			let notifAction = {
				data: rowdata
			};
			const { url_be_form } = JSON.parse( localStorage.getItem("user-data-url") );
			const myToken = localStorage.getItem("token");
			fetch(`${url_be_form}/Notifications/${action}`, {
			method: "POST",
			headers: {
				Authorization: `Bearer ${myToken}`,
				"Access-Control-Allow-Credentials": true,
				"Content-Type": "application/json",
			},
			body: JSON.stringify(notifAction),
			})
			.then((res) => res.json())
			.then((resp) => {
				// Si la respuesta no tiene error, deshabilita el botón
				if (resp.error === "") {
					const buttonKey = `${rowdata.id}_${action}`;
					setDisabledButtons(prev => ({
						...prev,
						[buttonKey]: true
					}));
					if (action === "autorizar"){
						const buttonKey = `${rowdata.id}_rechazar`;
						setDisabledButtons(prev => ({ ...prev, [buttonKey]: true }));
					} else if (action === "rechazar"){
						const buttonKey = `${rowdata.id}_autorizar`;
						setDisabledButtons(prev => ({ ...prev, [buttonKey]: true }));
					}
				}
				toast.current.show({
				severity: resp.error === "" ? "info" : "error",
				summary:
					resp.error === ""
					? resp.mensaje 
					: "Error " + resp.error,
				detail: rowdata.proceso,
				life: 2000,
				});
			})
			.catch(error => {
				console.error("Error en la petición:", error.message);
				toast.current.show({
					severity: "error",
					summary: "Error en la solicitud " + action,
					detail: error.message,
					life: 3000,
				});
			});
		
		} catch (error) {
			console.log("ERROR: ", error);
		}
	}

	const callNotifPrint = (rowdata, option) => {
		try {
			let notifPrint = {
				data: rowdata
			};
			const { url_be_form } = JSON.parse( localStorage.getItem("user-data-url") );
			const myToken = localStorage.getItem("token");
			fetch(`${url_be_form}/Notifications/${option}`, {
			method: "POST",
			headers: {
				Authorization: `Bearer ${myToken}`,
				"Access-Control-Allow-Credentials": true,
				"Content-Type": "application/json",
			},
			body: JSON.stringify(notifPrint),
			})
			.then(response => response.blob())  // Procesar la respuesta como Blob
			.then(blob => {
				const url = URL.createObjectURL(blob); // Creamos una URL temporal para el Blob
				//window.open(url, "_blank"); // Abre el PDF en una nueva pestaña
				 
				const newWindow = window.open(url, "_blank"); // Abrimos en una nueva ventana
				if (!newWindow) {
					// Si el navegador bloquea la ventana emergente, mostramos una alerta
					alert("Por favor, permite ventanas emergentes para ver el PDF.");
				}; /*else {
					newWindow.onload = () => {
						newWindow.print(); // Muestra el cuadro de dialogo de la impresion automáticamente
					};
				}*/ 
			})
			.catch(error => {
				console.error("Error al generar el PDF:", error.message);
				toast.current.show({
					severity: "error",
					summary: "Error al generar el PDF",
					detail: error.message,
					life: 3000,
				});
			});
		} catch (error) {
			console.log("ERROR: ", error);
		}
	}
	
	const callProccesA = async (data) => {
		try {
			const processTitle = data.nombre;
			const { url_be_form, url_fe_form } = JSON.parse( localStorage.getItem("user-data-url") );
			const { company, country } = JSON.parse( localStorage.getItem("user-session") );
			const token = localStorage.getItem("token");
			const lang = localStorage.getItem("language");
			const { user, conn } = jwt_decode(token); 
			const operacion = data.operacion;
			const body = {
				PROCESO: data.formulario, 
				URL_FRONT: url_fe_form,
				URL_BE: url_be_form,
				TIPO_EJECUCION: "F", // data.tipo_ejecucion,
				OPERACION: operacion,
				EMPRESA: company, //va en el token
				PAIS: country, //va en el token
				USUARIO: user, //va en el token
				conn: conn, //va en el token
				IDIOMA: lang,
				FE_AUTO: "N",
				CLAVE: {
					"nro_trans": data.nro_trans
				},
			};
			//console.log("BODY: ", body);
			const urlDomain = JSON.parse(localStorage.getItem("user-data-url"));
			const domain = urlDomain.url_be_menu; 
			const uri = await getUrl(domain, "postForms", "");
			const res = await fetch(uri, {
				method: "POST",
				headers: {
					Authorization: `bearer ${token}`,
					"Content-Type": "application/json",
					Accept: "application/json",
				},
				body: JSON.stringify(body),
			});
			//console.log(res);
			if (res.status == 201) {
				const response = await res.json();
				//console.log("SUCCESS: ", response);
				setheaderTitle(processTitle); //agrego el titulo del proceso y no el nombre
				setContentModal(response.url_form);
				setShowMenuModal(true);
			} else {
				console.log("ERROR: ", "BAD STATUS");
			}
		} catch (error) {
			console.log("ERROR: ", error);
		}
	};	

	const onClicRow = (element, data) => {
		const { proceso, tipo_proceso, id, nombre, 
			crear, autorizar, rechazar, visualizar, 
			imprimir, envia_mail, operacion } = data;
		const myData = {
			...element,
			proceso,
			tipo_proceso,
			processId: id,
			nombre,
			crear,
			autorizar,
			rechazar,
			visualizar,
			imprimir,
			envia_mail,
			operacion,
		};

		const buttons = [
			{
				id: 1,
				onClick: () => executeOption("imprimir", myData),
				tooltip: "Imprimir",
				icon: "fas fa-print",
				showButton: imprimir === "S",
				severity:"info"
			},
			{
				id: 2,
				onClick: () => executeOption("visualizar", myData),
				tooltip: "Visualizar",
				icon: "fas fa-eye",
				showButton: visualizar === "S",
			},
			{
				id: 3,
				onClick: () => executeOption("autorizar", myData),
				tooltip: "Autorizar",
				icon: "far fa-check-circle",//"fas fa-traffic-light",
				showButton: autorizar === "S",
				severity: "success",
				actionType: "autorizar"
			},
			{
				id: 4,
				onClick: () => executeOption("rechazar", myData),
				tooltip: "Rechazar",
				icon: "fas fa-times-circle",
				showButton: rechazar === "S",
				severity:"danger",
				actionType: "rechazar"
			},
			{
				id: 5,
				onClick: () => executeOption("envia_mail", myData),
				tooltip: "Enviar mail",
				icon: "fas fa-envelope",
				showButton: envia_mail === "S",
				severity: "warning",
				actionType: "envia_mail"
			},
			{
				id: 6,
				onClick: () => executeOption("nuevo", myData),
				tooltip: "Nuevo",
				icon: "far fa-file",
				showButton: crear === "S",
				actionType: "nuevo"
			},
		];

		return (
			<div style={{ display: "flex", justifyContent: "center", gap: "8px" }}>
				{buttons.map(({ id, tooltip, icon, onClick, showButton, severity, actionType }) => 
					showButton ? (
					<div key={id} className="button--row--container">
						<Button
							type="button"
							className="p-button-rounded p-button-icon-only"
							onClick={onClick}
							tooltip={tooltip} 
							severity={severity}
							tooltipOptions={{
								position: "bottom",
								mouseTrack: true,
								mouseTrackTop: 15,
							}}
							disabled={disabledButtons[`${myData.id}_${actionType}`]}
						>
							<i className={icon}></i>
						</Button>
					</div>
				):null )}
			</div>
		);
	};

	const styleColumn = (rowData, column) => {
		const { name, type } = column;
		switch (type) {
			case "float":
				return formatFloat(rowData[name]); //no aplica
				//return rowData[name];
			case "integer":
				return rowData[name];
			case "date":
				//const myDate = new Date(rowData[name]); //da error
				return formatDate(rowData[name]);
			case "hide": //agrego para ocultar mp 30/09/2021
				return "";
			default:
				return rowData[name];
		}
	};

	const dynamicColumns = (columns) => {
		return columns
        .filter((column) => column.type !== "hide") //Filtra las columnas ocultas
        .map((column) => (
            <Column
                key={column.name}
                field={column.name}
                header={column.header}
                body={(row) => styleColumn(row, column)}
                sortable
                //filter //por ahora no agrego filtro
            />
        ));
	};

	const rowExpansionTemplate = (data) => {
		const columns = data.columns;
		return (
			<div className="orders-subtable">
				<DataTable value={data.pendientes}>
					{dynamicColumns(columns)}
					<Column
						header="Acciones" 
						style={{ textAlign: "center", width: "100px" }}
						body={(elem) => onClicRow(elem, data)}
					></Column>					
				</DataTable>
			</div>
		);
	};

	// Este es el modal del form de alchemy
	const menuModal = (
		<Dialog
			className="custom-dialog-form"
			header={headerTitle}
			visible={showMenuModal}
			style={{ width: "70vw", maxWidth: "90vw", height: '50vw', padding:'0.1rem'}}
			onHide={() => setShowMenuModal(false)}
		>
			<iframe
				title="ifm"
				src={contentModal}
				style={{ width: '100%', height: '99%', border: 'none'}}
			/>
		</Dialog>
	);
	// fin modal de alchemy

	return (
		<div className="datatable-rowexpansion-demo" 
			 style={{ border: "2px solid #a6d5fa", borderRadius: "4px", padding: "4px"}}>
			<div className="card">
			{loading ? ( 
                    <div style={{ display: "flex", justifyContent: "center", padding: "20px" }}>
                        <ProgressSpinner />
                    </div>
                ) : ( 
				<DataTable
					value={notificationsData}
					expandedRows={expandedRows}
					onRowToggle={(e) => setExpandedRows(e.data)}
					rowExpansionTemplate={rowExpansionTemplate}
					dataKey="id"
					loading={loading}
					style={{ fontWeight: "bold" }}
				>
					<Column expander style={{ width: "3em" }} />
					<Column field="id" header="id" style={{ display: "none" }}></Column>
					<Column field="nombre" header="Proceso" ></Column>
					<Column field="cantidad" header="Cantidad"></Column>
				</DataTable>
				)}
			</div>
			{menuModal}
			<Toast ref={toast} position="center" />
		</div>
	);
};
