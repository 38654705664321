const loadConfig = async () => {
    try {
        const response = await fetch("config/config.json");
        if (!response.ok) throw new Error("Error al cargar config.json");
        return await response.json();
    } catch (error) {
        console.error("No se pudo cargar la configuración:", error);
        return {}; // Retorna un objeto vacío si hay error
    }
};

export default loadConfig;
