import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Messages } from "primereact/messages";
import { Dropdown } from "primereact/dropdown";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import { Base64 } from "js-base64";
import { Password } from "primereact/password";
import { classNames } from "primereact/utils";
import { useForm, Controller } from "react-hook-form";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { getUrl, getDomain } from "../../actions/getUrl";
import "./confirmLoginScreen.css";
import { Footer } from "../../components/footer/footer"; //agregadp 19/04/2024
import { FormInputText } from "../../common/formInputText/formInputText";
import { ModalPin } from "../../components/modalPin/modalPin";

export const ConfirmLoginScreen = () => {
	const { t, i18n } = useTranslation("global");
	const dispatch = useDispatch();
	const msgs1 = useRef(null);

	const [visiblePinModal, setVisiblePinModal] = useState(false);
	const [pin, setPin] = useState("");

	let userInfo;
	try {
		/* **************** */
		const queryString = window.location.search;
		const urlParams = new URLSearchParams(queryString);
		const sessionParam = urlParams.get("session");
		const decoded = Base64.decode(sessionParam);
		userInfo = JSON.parse(decoded);
		//console.log('RefLogout: ', userInfo.reflogout);
		/* **************** */
	} catch (error) {
		//const urlDomain = getDomain("logoutRedirect"); //comento mp 30/11/2021
		//window.location.href = urlDomain; //comento mp 30/11/2021
		console.log("ERROR - ConfirmLoginScreen: ", error);
	}

	const [connList, setConnList] = useState(null);
	const [selectedConnection, setSelectedConnection] = useState(null);

	const [sessionData, setSessionData] = useState("");
	const [userData, setUserData] = useState(null);

	let defaultValues = {
		company: userInfo.company,
		user: userInfo.user,
		password: "",
	};

	const {
		control,
		formState: { errors },
		handleSubmit,
	} = useForm({ defaultValues });

	const getFormErrorMessage = (name) => {
		return (
			errors[name] && (
				<small className="p-error">{errors[name].message}</small>
			)
		);
	};

	const header = <div className="alchemy--logo--container" />;

	const onConnectionChange = (e) => {
		setSelectedConnection(e.value);
	};

	const mapConnections = (list) => {
		return list.map((element) => {
			return {
				...element,
				name: element.connection,
				code: element.connection,
			};
		});
	};

	const addMessages = (sev, summ, det) => {
		msgs1.current.show([
			{
				severity: sev,
				summary: `${summ} `,
				detail: ` ${det}`,
				sticky: null,
				life: 3000,
			},
		]);
	};

	const onSubmit = async ({ password }) => {
		const { company, user, country } = userData;
		const param = `company=${company}&userid=${user}&password=${password}&connection=${selectedConnection?.connection}&country=${country}`;
		const urls = {
			url_be_form: selectedConnection?.url_be_form,
			url_fe_form: selectedConnection?.url_fe_form,
			url_fe_menu: selectedConnection?.url_fe_menu,
			url_be_menu: selectedConnection?.url_be_menu,
		};
		localStorage.setItem("user-data-url", JSON.stringify(urls));
		const userUrls = JSON.parse(localStorage.getItem("user-data-url"));
		const domain = userUrls.url_be_menu;
		const uri = await getUrl(domain, "login2", param);
		try {
			const res = await fetch(uri);
			const response = await res.json();
			if (response.success) {
				//console.log("pin-test:", response.branch);
				setUserData({ ...userData, password: password });
				if (response.noRobot) { //solo si tiene 2fa
					if (response.email){
						setVisiblePinModal(true); //pide pin
					}
					else { //el usuario no tiene mail definido entonces no se puede enviar pin
						addMessages("error", "", t("login2.emailError"));
						return;
					}
				} else {
					//todo ok
					localStorage.setItem(
						"user-session",
						JSON.stringify({ ...response, country })
					);
					localStorage.setItem("token", response.token);
					localStorage.setItem("logged2", true);
					dispatch({ type: "REQUEST_SESSION_SUCCESS" });
					window.location.href = "/";
				}
			} else {
				addMessages("error", "", t("login2.errors"));
			}
			return;
		} catch (error) {
			console.log("error onSubmit:", error);
			addMessages("error", "", t("login2.errors"));
		}
	};

	const onSubmitPin2FA = async () => {
		const { company, user, country, password } = userData;
		const param = `company=${company}&userid=${user}&password=${password}&connection=${selectedConnection.connection}&country=${country}&nropin=${pin}`;
		const userUrls = JSON.parse(localStorage.getItem("user-data-url"));
		const domain = userUrls.url_be_menu;
		const uri = await getUrl(domain, "login2", param);
		//console.log("uri", uri);

		try {
			const res = await fetch(uri);
			const response = await res.json();
			//console.log("response 2:", response);
			if (response.success) {
				localStorage.setItem(
					"user-session",
					JSON.stringify({ ...response, country })
				);
				//console.log("token", response.token);
				localStorage.setItem("token", response.token);
				localStorage.setItem("logged2", true);
				dispatch({ type: "REQUEST_SESSION_SUCCESS" });
				window.location.href = "/";
			} else {
				addMessages("error", "", t("login2.errors"));
			}
			return;
		} catch (error) {
			addMessages("error", "", t("login2.errors"));
		}
	};

	useEffect(() => {
		try {
			const queryString = window.location.search;
			const urlParams = new URLSearchParams(queryString);
			const sessionParam = urlParams.get("session");
			setSessionData(sessionParam);
			const decoded = Base64.decode(sessionParam);
			const userInfo = JSON.parse(decoded);
			setUserData(userInfo);
			const connections = userInfo.connections;
			const myConnections = mapConnections(connections);
			setConnList(myConnections);
			if (myConnections.length === 1) {
				setSelectedConnection(myConnections[0]);
			}
			i18n.changeLanguage(userInfo.language);
			localStorage.setItem("language", userInfo.language);
			localStorage.setItem("reflogout", userInfo.reflogout); //agregado mp 30/11/2021
		} catch (error) {
			const urlDomain = userInfo.reflogout; //getDomain("logoutRedirect"); //cambio mp 30/11/2021
			window.location.href = urlDomain;
		}
	}, []);

	return (
		<div className="confirm--screen--main--container">
			<Card header={header} style={{ width: "400px", zIndex: "100" }}>
				<form onSubmit={handleSubmit(onSubmit)} className="p-fluid">
					{/* *************************************************** */}

					<div className="p-field p-col-12 p-md-6">
						<Controller
							name="company"
							control={control}
							rules={{
								required: "required",
								pattern: { value: /[A-Za-z0-9._%+-]$/i },
							}}
							render={({ field, fieldState }) => (
								<FormInputText
									field={field}
									fieldState={fieldState}
									classNameIcon={"far fa-building"}
									placeholder={t(`login2.${field.name}`)}
									errorMessage={t(`login2.errors`)}
									disabled={true}
								/>
							)}
						/>
					</div>

					{/* *************************************************** */}
					<div className="p-field p-col-12 p-md-6">
						<Controller
							name="user"
							control={control}
							rules={{ required: "Name is required." }}
							render={({ field, fieldState }) => (
								<FormInputText
									field={field}
									fieldState={fieldState}
									classNameIcon={"far fa-user-circle"}
									placeholder={t(`login2.${field.name}`)}
									errorMessage={t(`login2.errors`)}
									disabled={true}
								/>
							)}
						/>
					</div>
					{/* *************************************************** */}
					<div
						className="p-field p-col-12 p-md-6"
						style={{ margin: "28px 10px" }}
					>
						<span className="p-float-label">
							<Controller
								name="password"
								control={control}
								rules={{ required: "Password is required." }}
								render={({ field, fieldState }) => (
									<Password
										feedback={false}
										toggleMask
										id={field.name}
										{...field}
										className={classNames({
											"p-invalid": fieldState.invalid,
										})}
									/>
								)}
							/>
							<label
								htmlFor="password"
								className={classNames({
									"p-error": errors.password,
								})}
							>
								{t("login2.password")}
							</label>
						</span>
						{getFormErrorMessage("password")}
					</div>
					{/* *************************************************** */}

					{connList && (
						<div style={{ margin: "28px 10px" }}>
							<Dropdown
								value={selectedConnection}
								options={connList}
								onChange={onConnectionChange}
								optionLabel="name"
								disabled={connList.length === 1}
								placeholder={t("login2.connection")}
							/>
						</div>
					)}

					<div className="login--screen--main--container--inputs--link">
						<Link
							to={`/auth/recover?session=${sessionData}`}
							className="link"
						>
							{t("login2.forgetPassword")}
						</Link>
					</div>

					<div
						className="p-field p-col-12 p-md-6"
						style={{ margin: "28px 10px" }}
					>
						<Button
							type="submit"
							label={t("login2.confirm")}
							className="p-button-info p-button-raised"
						/>
					</div>
				</form>
				<Messages ref={msgs1} />
				<ModalPin
					visible={visiblePinModal}
					setVisible={setVisiblePinModal}
					pin={pin}
					setPin={setPin}
					onSubmit={onSubmitPin2FA}
					headerTitle={t("login2.2faTitle")}
					bodyMessage={t("login2.2faMessage")}
					footerMessage={t("login2.2faFooter")}
				/>
			</Card>
			<Footer />
		</div>
	);
};
